html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  flex: 1 1 0%;
}
body input,
body textarea,
body .MuiAutocomplete-root input,
body .MuiInputBase-root input {
  font-size: 14px !important;
}
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
}
@media(max-width: 600px) {
  body input,
  body textarea,
  body .MuiAutocomplete-root input,
  body .MuiInputBase-root input {
    font-size: 16px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
